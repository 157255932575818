import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: or}}\n<iconify-icon\n  data-test-ak-icon\n  ...attributes\n  icon={{this.iconName}}\n  class='ak-icon'\n  local-class='\n    ak-icon-root\n    ak-icon-size-{{or @size \"medium\"}}\n    ak-icon-color-{{or @color \"inherit\"}}'\n/>", {"contents":"{{! @glint-nocheck: or}}\n<iconify-icon\n  data-test-ak-icon\n  ...attributes\n  icon={{this.iconName}}\n  class='ak-icon'\n  local-class='\n    ak-icon-root\n    ak-icon-size-{{or @size \"medium\"}}\n    ak-icon-color-{{or @color \"inherit\"}}'\n/>","moduleName":"irene/components/ak-icon/index.hbs","parseOptions":{"srcName":"irene/components/ak-icon/index.hbs"}});
import Component from '@glimmer/component';
import type { AkIconVariantType } from 'ak-icons';

export type AkIconColorVariant =
  | 'inherit'
  | 'textPrimary'
  | 'textSecondary'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warn';

export interface AkIconSignature {
  Element: HTMLSpanElement;
  Args: {
    variant?: 'filled' | 'outlined';
    iconName: AkIconVariantType;
    size?: 'medium' | 'small';
    color?: AkIconColorVariant;
  };
}

export default class AkIconComponent extends Component<AkIconSignature> {
  get iconName() {
    const iconName = this.args.iconName;

    if (!iconName) {
      return '';
    }

    const [pkg, icon] = iconName.includes(':')
      ? iconName.split(':')
      : ['material-symbols', iconName];

    if (this.args.variant === 'outlined' && pkg === 'material-symbols') {
      return `${pkg}:${icon}-outline`;
    }

    return `${pkg}:${icon}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AkIcon: typeof AkIconComponent;
  }
}
