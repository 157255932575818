import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span\n  data-test-fileDetailsSummary-fileOverview-appPlatform\n  local-class='app-platform-icon-{{this.platformIconClass}}'\n>\n  <AkIcon @iconName={{this.platformIconName}} local-class='app-platform-icon' />\n</span>", {"contents":"<span\n  data-test-fileDetailsSummary-fileOverview-appPlatform\n  local-class='app-platform-icon-{{this.platformIconClass}}'\n>\n  <AkIcon @iconName={{this.platformIconName}} local-class='app-platform-icon' />\n</span>","moduleName":"irene/components/file-details/summary/app-platform/index.hbs","parseOptions":{"srcName":"irene/components/file-details/summary/app-platform/index.hbs"}});
import Component from '@glimmer/component';
import FileModel from 'irene/models/file';

export interface FileDetailsSummaryAppPlatformSignature {
  Args: {
    file: FileModel;
  };
}

export default class FileDetailsSummaryAppPlatformComponent extends Component<FileDetailsSummaryAppPlatformSignature> {
  get platformIconClass() {
    return this.args.file.project.get('platformIconClass');
  }

  get platformIconName() {
    if (this.platformIconClass === 'apple') {
      return 'fa-brands:apple';
    } else if (this.platformIconClass === 'android') {
      return 'android';
    }

    return '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FileDetails::Summary::AppPlatform': typeof FileDetailsSummaryAppPlatformComponent;
    'file-details/summary/app-platform': typeof FileDetailsSummaryAppPlatformComponent;
  }
}
